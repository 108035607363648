import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

const sharedService = new SharedService();


export class PropertyAdminService {

  private APIURL = environment.APIURL;
  async getAllProperties()
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/propertyAdmin/getAllProperties`,
    {headers:await sharedService.getAuthHeader()});
  }
  
  async getPendingwithdrawalPropertyDetails()
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/propertyAdmin/getPendingwithdrawalPropertyDetails`,
    {headers:await sharedService.getAuthHeader()});
  }

  async getPropertyById(propertyId: any)
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/propertyAdmin/getPropertyById/${propertyId}`,
    {headers:await sharedService.getAuthHeader()});
  }
  async getWhiteListedProperties()
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/propertyAdmin/getWhiteListedProperties`,
    {headers:await sharedService.getAuthHeader()});
  }
  
//   async updateKYC(data:any)
//   {
//     return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/updateKYC`,data,{headers:await sharedService.getAuthHeader()});
//   }
}
