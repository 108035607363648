import React, { useState, useEffect, Fragment } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Steps,
  message,
  Spin,
  Result,
  notification,
} from 'antd';
import { CheckOutlined, LineOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  TokenConfigurationProcess,
  SymbolDetailsAndSTData,
} from '../../Shared/interfaces';
import { AuthService } from '../../Shared/Auth.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import { TokenConfigurationService } from '../TokenConfiguration.service';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import TypeAndDetails from './TypeAndDetails';
import Rule144 from './Rule144';
import CreateToken from './CreateToken';
import { SharedService } from '../../Shared/Shared.service';
import queryString from 'query-string';
import OfferingModels from './OfferingModels';

const { Title } = Typography;
const { Step } = Steps;

const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();

const configTypeToCurrentStep = {
  'type-and-details': 0,
  'rule-144': 1,
 // phase: 2,
  create: 2,
};
const currentStepToConfigType = Object.fromEntries(
  Object.entries(configTypeToCurrentStep).map((entry) => entry.reverse())
);

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

export default function TokenConfigurationDetails() {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();

  const history = useHistory();
  const { configType, tokenConfigurationProcessId } =
    useParams<{ configType: string; tokenConfigurationProcessId: string }>();

  const [tokenConfigurationProcess, setTokenConfigurationProcess] =
    useState<TokenConfigurationProcess>();
  const [loading, setLoading] = useState(true);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] =
    useState<SymbolDetailsAndSTData>();

  const location = useLocation();
  const { relaunchRegulation } = queryString.parse(location.search);

  const [isRelaunch, setIsRelaunch] = useState<boolean>();

  const [adminsRolesCreated, setAdminsRolesCreated] =
    useState<{ name: string; value: string; exists: boolean }[]>();

  const { regulation, prevTokenConfigurationProcessId: _prevTokenConfigurationProcessId } = queryString.parse(location.search);

  const areAdminsRolesCreated = !adminsRolesCreated?.find(
    (role) => !role.exists
  );

  useEffect(() => {
    (async () => {
      const [tokenConfigRes, adminCreatedRes] = await Promise.all([
        tokenConfigurationService.getTokenConfigurationProcessById({
          tokenConfigurationProcessId,
        }),
        tokenConfigurationService.adminsRolesCreated(),
      ]);

      const _tokenConfigurationProcess: TokenConfigurationProcess =
        tokenConfigRes.data;
      setTokenConfigurationProcess(_tokenConfigurationProcess);
      setAdminsRolesCreated(adminCreatedRes.data);
      setIsRelaunch(
        _tokenConfigurationProcess?.isRelaunch || !!relaunchRegulation
      );

      if (_tokenConfigurationProcess?.tokenSymbol) {
        const _symbolDetailsAndSTData =
          await securityTokenRegisteryService.getSymbolDetailsAndSTData(
            _tokenConfigurationProcess.tokenSymbol
          );
        setSymbolDetailsAndSTData(_symbolDetailsAndSTData);
      }

      setLoading(false);
    })();
  }, [relaunchRegulation, tokenConfigurationProcessId]);

  useEffect(() => {
    if (loading) return;

    let redirectConfigType: string | null = null;

    if (configType === 'rule-144') {
      if (!tokenConfigurationProcess) redirectConfigType = 'type-and-details';
    } else if (configType === 'phase') {
      if (!tokenConfigurationProcess) redirectConfigType = 'type-and-details';
      else if (tokenConfigurationProcess.status === 'rule 144'
      )
        redirectConfigType = 'rule-144';
    } else if (configType === 'create' ) {
      if (!tokenConfigurationProcess) redirectConfigType = 'type-and-details';
      else if (tokenConfigurationProcess.status === 'rule 144' || (!tokenConfigurationProcess?.legendCreationFile || !tokenConfigurationProcess?.isLegendAdded) )
        redirectConfigType = 'rule-144';
      // else if (tokenConfigurationProcess.status === 'phase')
      //   redirectConfigType = 'phase';
    }

    // console.log(redirectConfigType);

    if (!redirectConfigType) return;
    history.push(
      `/token-configurations/${tokenConfigurationProcessId}/${redirectConfigType}`
    );
  }, [configType, tokenConfigurationProcess, history, loading]);

  const saveTokenTypeAndDetails = async (req) => {
    const response = await tokenConfigurationService.saveTokenTypeAndDetails(
      req
    );

    if (response.success) {
      if (!tokenConfigurationProcess) {
        setTokenConfigurationProcess({
          _id: response.data.tokenConfigurationProcessId,
          tokenTypeAndDetailsId: response.data.tokenTypeAndDetailsId,
          status: 'rule 144',
          companyId: userInfo?.company?.id as string,
          // issuer: userInfo?._id as string,
          creationTS: Date.now(),
          typeOfSecurity: req.typeOfSecurity,
        });
      }

      message.success('Token type and details saved successfully');
      history.push(
        `/token-configurations/${response.data.tokenConfigurationProcessId}/rule-144`
      );
    } else {
      message.error(response.error.message);
    }
    return response;
  };

  const saveRule144Config = async (req) => {
    const response = await tokenConfigurationService.saveRule144Config(req);

    if (response.success) {
      if (tokenConfigurationProcess?.rule144ConfigId) {
        setTokenConfigurationProcess((prev) => {
          const current = sharedService.clone(prev);
          if (current?.lockPeriod) current.lockPeriod = req.lockPeriod;
          return current;
        });
      } else {
        setTokenConfigurationProcess((prev: any) => ({
          ...prev,
          status: 'rule 144',
          rule144ConfigId: response.data,
        }));
      }

      message.success('Rule 144 configuration saved successfully');
      // history.push(
      //   `/token-configurations/${tokenConfigurationProcessId}/phase`
      // );
      history.push(
        `/token-configurations/${tokenConfigurationProcessId}/create`
      );
    } else {
      message.error(response.error.message);
    }
    return response;
  };

  const sendRequestToCreateLegend = async () => {
    const response = await issuerSuperAdminService.sendRequestToCreateLegend();

    if (response.success) {
      //setTokenConfigurationProcess((prev:any) => ({...prev, isLegendAdded: true}));
    } else {
      message.error(response.error);
    }

    return response;
  };

  const addLegend = async () => {
    const response = await tokenConfigurationService.addLegend({
      tokenConfigurationProcessId,
      companyId: userInfo?.company?.id
    });

    if (response.success) {
      setTokenConfigurationProcess((prev: any) => ({
        ...prev,
        isLegendAdded: true,
        status: 'token creation'
      }));
      message.success('Legend Added successfully');

      history.push(
        `/token-configurations/${tokenConfigurationProcessId}/create`
      );
    } else {
      message.error(response.error);
    }

    return response;
  };

  const saveOfferingModel = async (req) => {
    const response = await tokenConfigurationService.saveOfferingModel(req);

    if (response.success) {
      message.success('Model saved successfully');
    } else {
      message.error(response.error.message);
    }
    return response;
  };

  const savePhase = async (req) => {
    const response = await tokenConfigurationService.savePhase(req);

    if (response.success) {
      if (tokenConfigurationProcess?.status === 'phase') {
        setTokenConfigurationProcess((prev: any) => ({
          ...prev,
          status: 'token creation',
        }));
      }

      message.success('Phase saved successfully');
    } else {
      if (response.error.code === 'overlappedPhase') {
        notification.error({
          message: 'Phase Overlaps',
          description: response.error.message,
          duration: 0,
        });
      } else {
        message.error(response.error.message);
      }
    }
    return response;
  };

  const editPhase = async (req) => {
    const response = await tokenConfigurationService.editPhase(req);

    if (response.success) {
      message.success('Phase saved successfully');
    } else {
      message.error(response.error.message);
    }
    return response;
  };

  const reserveTokenSymbol = async (formValue) => {
    const response = await tokenConfigurationService.saveTokenSymbolReservation(
      formValue
    );
    console.log(response);
    if (!response.success) return null;

    const _expiryLimit = `${1 * 30 * 24 * 3600}`;

    const transactionReceipt =
      await securityTokenRegisteryService.registerNewTokenSymbol(
        selectedWallet as string,
        formValue.tokenSymbol,
        _expiryLimit,
        {delegate: formValue.delegate}
      );
    if (transactionReceipt.status) {
      setTokenConfigurationProcess((prev: any) => ({
        ...prev,
        tokenSymbol: formValue.tokenSymbol,
      }));
    }

    return transactionReceipt;
  };

  const updateSymbolDetailsAndSTData = async () => {
    const _symbolDetailsAndSTData =
      await securityTokenRegisteryService.getSymbolDetailsAndSTData(
        tokenConfigurationProcess?.tokenSymbol as string
      );
      // const payload:any = {...tokenConfigurationProcess}
    setSymbolDetailsAndSTData(_symbolDetailsAndSTData);
    return _symbolDetailsAndSTData;
  };

  const persistTokenAddress = async(data) =>{
    try {
        const response = await tokenConfigurationService.saveTokenTypeAndDetails(
          {tokenAddress:data?.securityTokenData.contractAddress}
        );
        if(response.success){
          message.success('Token captured successfully!')
        }else{
          message.error('Error while capturing token!')
        }
        return true
      } catch (error) {
        throw error
      }
  }

  const submitTokenConfigForReview = async () => {
    const response = await tokenConfigurationService.submitTokenConfigForReview(
      { tokenConfigurationProcessId }
    );

    if (response.success) {
      setTokenConfigurationProcess((prev) => {
        const current = sharedService.clone(prev);
        if (current?.status) current.status = 'pending issuer super admin';
        return current;
      });
    } else {
      message.error(response.error.message);
    }

    return response;
  };

  const reviewFinalTokenConfig = async (
    status: 'verified' | 'rejected',
    comments: string
  ) => {
    const response = await tokenConfigurationService.reviewFinalTokenConfig({
      status,
      comments,
      tokenConfigurationProcessId,
      companyId: userInfo?.company?.id
    });

    if (response.success) {
      setTokenConfigurationProcess((prev) => {
        const current = sharedService.clone(prev);

        let nextStatus: any;

        if (userInfo?.role === 'issuer super admin') {
          if (status === 'verified')
            nextStatus = 'pending issuer company officer';
          else nextStatus = 'rejected issuer super admin';
        } else if (userInfo?.role === 'issuer company officer') {
          if (status === 'verified') nextStatus = 'pending issuer counsel';
          else nextStatus = 'rejected issuer company officer';
        } else if (userInfo?.role === 'issuer counsel') {
          if (status === 'verified') nextStatus = 'verified';
          else nextStatus = 'rejected issuer counsel';
        }

        if (current?.status) current.status = nextStatus;

        return current;
      });
    } else {
      message.error(response.error.message);
    }

    return response;
  };

  const submitCheckboxesStatus = async () => {
    const response = await tokenConfigurationService.submitCheckboxesStatus();

    if (response.success) {
      setTokenConfigurationProcess((prev) => {
        const current = sharedService.clone(prev);
        (current as any).allCheckboxesSelected = true;
        return current;
      });
      message.success('Form submitted successfully');
      setTimeout(()=> window.location.reload(), 1000);
    } else {
      message.error(response.error.message);
    }

    return response;
  };

  return (
    <>
      <br />
      <Title level={1} style={{ color: '#186AB4', textAlign: 'center' }}>
        TOKEN CONFIGURATION
      </Title>
      <Row justify="center" style={{ marginBottom: '50px' }}>
        <Col span={20}>
          {loading && (
            <div style={{ textAlign: 'center' }}>
              <br />
              <Spin size="large" />
            </div>
          )}

          {!loading && userInfo && (
              <Card>
                {
                    !areAdminsRolesCreated && (
                        <>
                          <Title level={4} style={{ marginBottom: '0' }}>
                            Before you begin the token creation process, it's
                            mandatory to add at least one Admin for each Admin Role
                            given below.{' '}
                          </Title>
                          <Title level={4}>
                            After all the 4 admins successfully registered on TAP,
                            your Property Company Token Admin will be able to begin token
                            creation process
                          </Title>

                          <Title
                              level={3}
                              style={{ color: 'dodgerblue', fontWeight: 'bold' }}
                          >
                            Admin Roles
                          </Title>

                          <div style={{ marginLeft: '15px' }}>
                            {adminsRolesCreated?.map((role, index) => (
                                <Fragment key={index}>
                                  <p style={{ color: 'dodgerblue' }}>
                                    {role.exists ? <CheckOutlined /> : <LineOutlined />}{' '}
                                    {role.name}
                                  </p>
                                </Fragment>
                            ))}
                          </div>
                        </>
                    )
                  // <Result title={`Account for Issuer Company Officer, Issuer Compliance Admin or Property Company Counsel not created`}/>
                }

                {/* THEY CAN ONLY SEE THE CONFIG ONLY IF THEY ARE REVIEWING OR THEY REVIEWED THE REQUEST*/}

                {areAdminsRolesCreated && (
                    <>
                      {userInfo?.role !== 'issuer token admin' &&
                          (!tokenConfigurationProcess ||
                              [
                                'phase',
                                'rule 144',
                                'token creation',
                                'rejected issuer super admin',
                              ].includes(tokenConfigurationProcess.status)) && (
                              <Result
                                  title={`Property Company Token Admin is configuring the Token`}
                              />
                          )}

                      {['issuer company officer', 'issuer counsel'].includes(
                              userInfo?.role
                          ) &&
                          tokenConfigurationProcess &&
                          [
                            'pending issuer super admin',
                            'rejected issuer company officer',
                          ].includes(tokenConfigurationProcess.status) && (
                              <Result
                                  title={`Property Super Admin is reviewing the Token`}
                              />
                          )}

                      {['issuer counsel'].includes(userInfo?.role) &&
                          tokenConfigurationProcess &&
                          [
                            'pending issuer company officer',
                            'rejected issuer counsel',
                          ].includes(tokenConfigurationProcess.status) && (
                              <Result
                                  title={`Property Company Officer is reviewing the Token`}
                              />
                          )}

                      {(userInfo?.role === 'issuer token admin' ||
                          (userInfo?.role === 'issuer super admin' &&
                              tokenConfigurationProcess &&
                              [
                                'pending issuer super admin',
                                'rejected issuer company officer',
                                'pending issuer company officer',
                                'rejected issuer counsel',
                                'pending issuer counsel',
                              ].includes(tokenConfigurationProcess.status)) ||
                          (userInfo?.role === 'issuer company officer' &&
                              tokenConfigurationProcess &&
                              [
                                'pending issuer company officer',
                                'rejected issuer counsel',
                                'pending issuer counsel',
                              ].includes(tokenConfigurationProcess.status)) ||
                          (userInfo?.role === 'issuer counsel' &&
                              tokenConfigurationProcess &&
                              ['pending issuer counsel'].includes(
                                  tokenConfigurationProcess.status
                              )) ||
                          tokenConfigurationProcess?.status === 'verified') && (
                          <>
                            <Steps
                                type="navigation"
                                current={configTypeToCurrentStep[configType]}
                                onChange={(step) =>
                                    history.push(
                                        `/token-configurations/${tokenConfigurationProcessId}/${currentStepToConfigType[step]}`
                                    )
                                }
                            >
                              <Step
                                  status={
                                    tokenConfigurationProcess ? 'finish' : undefined
                                  }
                                  title="Token type & details"
                              ></Step>
                              <Step
                                  disabled={!tokenConfigurationProcess}
                                  status={
                                    tokenConfigurationProcess &&
                                    tokenConfigurationProcess.isLegendAdded
                                        ? 'finish'
                                        : undefined
                                  }
                                  title="Rule 144"
                              ></Step>
                              {console.log(tokenConfigurationProcess)}
                              {/* <Step
                          disabled={
                            !tokenConfigurationProcess ||
                            tokenConfigurationProcess.status === 'rule 144'
                          }
                          status={
                            tokenConfigurationProcess &&
                            tokenConfigurationProcess.status !== 'rule 144' &&
                            tokenConfigurationProcess.status !== 'phase'
                              ? 'finish'
                              : undefined
                          }
                          title="Phase"
                        ></Step> */}
                              <Step
                                  disabled={
                                      !tokenConfigurationProcess ||
                                      !tokenConfigurationProcess.isLegendAdded
                                  }
                                  status={
                                    tokenConfigurationProcess &&
                                    tokenConfigurationProcess.status !== 'rule 144' &&
                                    tokenConfigurationProcess.status !== 'phase' &&
                                    symbolDetailsAndSTData?.symbolDetails.isDeployed
                                        ? 'finish'
                                        : undefined
                                  }
                                  title={isRelaunch ? 'Relaunch Token' : 'Create Token'}
                              ></Step>
                            </Steps>

                            {configType === 'type-and-details' && (
                                <TypeAndDetails
                                    userInfo={userInfo}
                                    saveTokenTypeAndDetails={saveTokenTypeAndDetails}
                                    tokenConfigurationProcess={tokenConfigurationProcess}
                                    isRelaunch={!!isRelaunch}
                                />
                            )}
                            {configType === 'rule-144' && (
                                <Rule144
                                    userInfo={userInfo}
                                    saveRule144Config={saveRule144Config}
                                    sendRequestToCreateLegend={sendRequestToCreateLegend}
                                    addLegend={addLegend}
                                    tokenConfigurationProcess={tokenConfigurationProcess}
                                    isRelaunch={!!isRelaunch}
                                />
                            )}
                            {/* {
                        configType === 'phase' && (
                          <OfferingModels
                            tokenConfigurationProcess={
                              tokenConfigurationProcess
                            }
                            userInfo={userInfo}
                            saveOfferingModel={saveOfferingModel}
                            savePhase={savePhase}
                            editPhase={editPhase}
                          />
                        )
                        // <Phases
                        //   userInfo={userInfo}
                        //   savePhase={savePhase}
                        //   editPhase={editPhase}
                        //   tokenConfigurationProcess={tokenConfigurationProcess}/>
                      } */}
                            {configType === 'create' && (
                                <CreateToken
                                    userInfo={userInfo}
                                    reserveTokenSymbol={reserveTokenSymbol}
                                    selectedWallet={selectedWallet}
                                    networkId={networkId}
                                    symbolDetailsAndSTData={symbolDetailsAndSTData}
                                    updateSymbolDetailsAndSTData={
                                      updateSymbolDetailsAndSTData
                                    }
                                    saveTokenAddress={persistTokenAddress}
                                    tokenConfigurationProcess={tokenConfigurationProcess}
                                    submitTokenConfigForReview={
                                      submitTokenConfigForReview
                                    }
                                    reviewFinalTokenConfig={reviewFinalTokenConfig}
                                    submitCheckboxesStatus={submitCheckboxesStatus}
                                    isRelaunch={!!isRelaunch}
                                />
                            )}
                          </>
                      )}
                    </>
                )}
              </Card>
          )}
        </Col>
      </Row>
    </>
  );
}
