import { Card, Col, Row, Spin, Table, Typography, notification, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from '../../../Shared/interfaces';
import { SharedService } from '../../../Shared/Shared.service';
import { ApproversAndAccountsService } from '../ApproversAndAccounts.service';

const {Title} = Typography;

const sharedService = new SharedService();
const approversAndAccountsService = new ApproversAndAccountsService();




export default function AddApprover() {

  const [loading, setLoading] = useState(true);
  const [issuers, setIssuers] = useState<User[]>();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();


  useEffect(() => {
    (async () => {
      const _issuers = (await approversAndAccountsService.getNonApproverIssuers()).data;

      setIssuers(_issuers);
      setLoading(false);

    })();
  }, []);



  const columns = [
    {
      title: 'NAME',
      dataIndex: 'firstName'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email'
    },
    {
      title: 'WALLET ADDRESS',
      dataIndex: 'walletAddress'
    },
    {
      title: 'ADMIN ROLES',
      dataIndex: 'roles',
      render: (roles: string[], record: User) => (
        sharedService.adminRolesOptions2.find(opt => roles.includes(opt.value))?
          sharedService.adminRolesOptions2.filter(opt => roles.includes(opt.value)).map(opt => opt.name).join('| ') 
          : 
          'None'
      )
    },
  ];

  const selectApproversFromTable = (selectedRows: User[]) => {
    const newData = sharedService.clone(issuers);
    newData?.forEach(approver => approver['selected'] = selectedRows.map(row => row._id).includes(approver._id));

    setIssuers(newData);
  }


  const addApprovers = async() => {
    if(!issuers?.find(approver => approver['selected'])) return;
    setSubmitting(true);

    const approversIds = issuers.filter((approver => approver['selected'])).map(approver => approver._id)

    const response = await approversAndAccountsService.addApprovers({
      approversIds
    });

    if (response.success) {
      notification.success({message: 'Success', description: 'Approver added successfully'});

      // const _issuers = sharedService.clone(issuers);
      // setIssuers(_issuers.filter(issuer => !approversIds.includes(issuer._id)));
      
      history.push(`/issuer-super-admin/approvers-and-accounts`);
      

    } else {
      notification.error({message: 'Error', description: response.error.message});
    }
    
    setSubmitting(false);
  }
  

  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={23}>
          {loading &&  
            <div style={{textAlign:'center'}}>
              <br/>
              <Spin size='large'/>
            </div>
          }
          {!loading && 
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Add Approvers</Title>

              <Table
                columns={columns}
                dataSource={issuers}
                rowKey='_id'
                pagination={false}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: issuers?.filter((approver => approver['selected'])).map(approver => approver._id),
                  onChange: (selectedRowKeys, selectedRows) => {
                    console.log(selectedRowKeys);
                    selectApproversFromTable(selectedRows);
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                  },
                }}
              />

              <div style={{textAlign:'right'}}>
                <Button style={{marginRight:'5px'}} htmlType='button' size='large' onClick={()=> history.push(`/issuer-super-admin/approvers-and-accounts`)}>
                  BACK
                </Button>
                <Button htmlType='submit' size='large' type='primary' onClick={addApprovers} loading={submitting}>
                  ADD APPROVERS
                </Button>
              </div>
            </Card>
          }
        </Col>
      </Row>
    </>
  );


  
}