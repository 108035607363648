import React,{useState, useEffect} from 'react';
import { Result, Card , Form, Col, Radio, Input, Button, Spin, notification} from 'antd';
import { AuthService } from '../../Shared/Auth.service'
import { InvestorService } from '../Investor.service';
import eversign from "./Eversign";
import './nda.css';

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()
const investorService = new InvestorService();

export default () => {
  const { userInfo, setUserInfo } = useUserContext();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [ndaSigned, setNdaSigned] = useState(false);
  const [ndaDetails, setNdaDetails] = useState(false);
  const [openEversign, setOpenEversign] = useState(false);
  const [signURL, setSignURL] = useState(null);
  const [accInvestor, setAccInvestor] = useState('false');
  const [repaint, setRepaint] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);

    useEffect(()=>{
      const data = {
        netWorth:userInfo?.netWorth,
        annualIncome:userInfo?.annualIncome,
        accreditedInvestor:userInfo?.accreditedInvestor,
      }

      form.setFieldsValue(data)

      if(userInfo.questionnaire === true){
        setNdaDetails(true);
      }
      if(userInfo.status.ndaSigned === true){
        setNdaSigned(true);
        return
      }
      
      (async () => {
        if(userInfo?.accreditedInvestor === 'true') setAccInvestor('true')
        const response = await investorService.getNDASignURL(userInfo);
        await setSignURL(response.data);
      })();
    },[repaint])

    useEffect(() => {
      setOpenEversign(signURL && !ndaSigned && ndaDetails)
    }, [signURL, ndaSigned])

  const register = async (formValue) => {
    try {
      console.log(formValue)
      const req = {...formValue};
      req.userId = userInfo._id;
      req.ndaDetails = true;
      req.accreditedInvestor = accInvestor;
      req.questionnaire = true;
      setSubmitting(true);
      let res = await investorService.submitNdaDetails(req);
      setNdaDetails(true);
      setSubmitting(false);
      setRepaint(p => !p)
      notification.success({message:'Success', description:'Questionnaire Saved Successfully!'});
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      notification.error('Something went wrong try again!')
      console.log(error)
    }
  }

    //ACCEPT T AND C STARTS
    const acceptTandC = async () => {
      try {
        const response = await investorService.setNDASigned({
          email: userInfo.email,
        });
        if (response.success && response.data) {
          userInfo.status.ndaSigned = true;
          notification.success({message:'Success', description:'NDA Submitted Successfully!'})
        }
      } catch (error) {
        notification.error({message:'Error', description:'Something went wrong!'})
        console.log(error)
      }
    };
    //ACCEPT T AND C ENDS

  const onSign = () => {
    acceptTandC();
    setNdaSigned(true);
  };

  return (
    <>
      {submitting &&
        <div style={{ textAlign: 'center' }}>
          <br />
          <Spin size='large' />
        </div>
      }

    {!submitting && ndaSigned &&  <Card style={{margin: '20px 50px', padding: '10px 40px'}}>
        <div class="alert alert-success p-5" role="alert">
            <span style={{fontSize:'17px',fontWeight:'bold'}}>
              You have successfully signed Investor NDA Agreement. You can continue to investment <a href={'dashboard'}>here</a>!
              </span>
          </div>
      </Card>}

    {!submitting && (<Card style={{margin: '20px 50px', padding: '20px 40px'}}>
      {/* {ndaSigned && (<h2 style={{color: 'blue', fontSize: '24px'}}></h2>)} */}
  
      

        <h1 style={{textAlign: 'center'}}>Investor Questionnaire</h1>
        <h4 style={{fontStyle: 'oblique', fontWeight: 'bold', textAlign: 'center'}}>
          This information is needed for us to comply with SEC and stable securities
          regulations.We ask the following questions to determine if the amount if
          the amount you may invest is limited by law.</h4>

        <Form form={form}
            onFinish={register}
            style={{ margin: '2% 10% 0 10%', width: '60%' }}>
          <h4 style={{fontWeight: 'bold', textAlign: 'justify'}}>                 
            Are you an "accrediated" investor (i.e do you earn over $200,000 per year, have
            a net worth of $1M or more ,or are an individual investor)?
          </h4>
          <Form.Item
            name="accreditedInvestor"
            rules={[
              {
                message: 'Please input whether you are a Accediated Investor or not!',
                whitespace: true
              }
            ]}>
            <Col style={{ textAlign: "left" }}>
              <Radio.Group defaultValue={userInfo?.accreditedInvestor} >
                <Radio key="true" value='true' disabled={ndaDetails} style={{ fontSize: '18px' }} onClick={()=>{setAccInvestor('true'); setIsMandatory(true)}}>
                  Yes
                </Radio>
                <Radio key="false" value='false' disabled={ndaDetails} style={{ fontSize: '18px' }} onClick={()=>{setAccInvestor('false'); setIsMandatory(false)}}>
                  No
                </Radio>
              </Radio.Group>      
              
            </Col>
          </Form.Item>

          <h4 style={{fontStyle: 'oblique', ontWeight: 'bold', textAlign: 'justify'}}>                                  
            Are you an "accrediated investor" the law limits the total amount you can
            invest based on your annual income and your net worth. Please provide these so
            that we may determine if the amount you wish to invest is within these limitation.
          </h4>
    
          <Form.Item 
            label='Annual Income'
            name="annualIncome"
            rules={[
              {
                required: isMandatory,
                message: 'Please input your Annual Income!',
                whitespace: true
              }              
            ]}
            style={{fontSize: '18px'}}>
            <Input type='number' addonAfter='USD' style={{fontSize: '18px'}} 
              defaultValue={userInfo.annualIncome} disabled={ndaDetails}/>
          </Form.Item>

          <Form.Item 
            label='Net Worth'
            name="netWorth"
            rules={[
              {
                required: isMandatory,
                message: 'Please input your Net Worth!',
                whitespace: true
              }
            ]}
            style={{fontSize: '18px'}}>
            <Input type='number' addonAfter='USD' style={{fontSize: '18px'}} 
              defaultValue={userInfo.netWorth} disabled={ndaDetails}/>
          </Form.Item>
          <br/>
          {!ndaDetails && (
            <Button type='primary' htmlType='submit' loading={submitting} size='large' 
              style={{marginLeft: '350px'}}>Submit</Button>)}
          {ndaDetails && (
            <Button type='primary' onClick={()=>setNdaDetails(false)} size='large' 
              style={{marginLeft: '350px'}}>Edit</Button>)}
        </Form>
        <br/>
        {!ndaSigned && (<h2 style={{color: 'blue', fontSize: '24px'}}>NDA</h2>)}
        {/* NDA View Starts Here */}
        <div hidden={ndaSigned} id="embedSignDivision">
          {openEversign &&
            eversign.open({
              url: signURL,
              containerID: "embedSignDivision",
              width: 750,
              height: 800,
              events: {
                loaded: () => console.log("loading successful"),
                signed: onSign,
                error: () => console.log("EVERSIGN ERROR"),
                declined: () => console.log("declined"),
              },
            })}
        </div>
        {/* NDA View ends here */}
      </Card>)}
    </>
  )
}