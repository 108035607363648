import axios from "axios";
import { environment } from "../../environments/environment";
import { APIResponse } from "../Shared/interfaces";
import { SharedService } from "../Shared/Shared.service";

const sharedService = new SharedService();

export class InvestorService {
  private APIURL = environment.APIURL;

  async getTokens() {
    return axios.post<any, APIResponse>(`${this.APIURL}/investor/getTokens`, {
      headers: await sharedService.getAuthHeader(),
    });
  }
  async getTokenDetails(targetDbString: string) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getTokenDetails?targetDbString=${targetDbString}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getBankDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getBankDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getUserRegisteredTokens(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getUserRegisteredTokens`,
      data,
      {
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  async addUserRegisteredToken(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/addUserRegisteredToken`,
      data,
      {
        headers: await sharedService.getAuthHeader(),
      }
    );
  }

  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append("doc", file);
    sharedService.uploadDocument(
      `${this.APIURL}/investor/uploadDocument`,
      req,
      progressCb,
      errorCb,
      completeCb
    );
  }

  async submitKYCDetails(formData: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/submitKYCDetails`,
      formData,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async submitAIDetails(formData: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/submitAIDetails`,
      formData,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getKYCDetails(investorId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getKYCDetails`,
      investorId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getAiDetails(investorId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getAiDetails`,
      investorId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getPayments(investorId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getPayments`,
      investorId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getCompanyDetails(investorId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getCompanyDetails`,
      investorId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async setFirstTimeInvestButtonClicked(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/setFirstTimeInvestButtonClicked`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async acceptTandC(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/setTandCAccepted`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async setNDASigned(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/setNDASigned`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  
  async getSignURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getSignURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getNDASignURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getNDASignURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getInvestSignURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getInvestSignURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getTestSignURL(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getTestSignURL`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async handleSigningDocument(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/handleSigningDocument`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async northCapitalWireTransferPaymentDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/northCapitalWireTransferPaymentDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async saveWireTransferPaymentDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/saveWireTransferPaymentDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTokenPhaseDetail(companyId: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getTokenPhaseDetail`,
      companyId,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getSubscriptionAgreement(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getSubscriptionAgreement`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async signSubscriptionAgreement(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/signSubscriptionAgreement`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getIssuerWebpageConfig(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getIssuerWebpageConfig`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getIssuerTokenInfo(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getIssuerTokenInfo`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getPrimeTrustEntity(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getPrimeTrustEntity`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getIssuerDueDiligence(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getIssuerDueDiligence`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async generateLinkForKYC(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/generateLinkForKYC`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async payUsingCoinbase(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/payUsingCoinbase`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async payUsingSkrill(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/payUsingSkrill`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getCoinbasePaymentDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getCoinbasePaymentDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getSkrillPaymentDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getSkrillPaymentDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async updateSkrillPaymentStatus(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/skrillStatus`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async completePayment(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/completePayment`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async payUsingOthers(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/payUsingOthers`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getSavedPaymentMethod(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getSavedPaymentMethod`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async saveNewWallet(data: any) {
    //TODO DELETE
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/saveNewWallet`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getPendingWalletForWhitelisting(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getPendingWalletForWhitelisting`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }
  async getInvestorsCount(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/getInvestorsCount`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async requestWalletWhitelist(prop: {
    wallet: string;
    tokenSymbol: string;
    country: string;
    isAccredetedInvestor: boolean;
    assetCreationTS: number;
  }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/requestWalletWhitelist`,
      prop,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getWalletWhitelistRequests(prop: { tokenSymbol: string }) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getWalletWhitelistRequests`,
      { headers: await sharedService.getAuthHeader(), params: prop }
    );
  }

  
  async getCompanyAndNonAccreditedInvestor() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getCompanyAndNonAccreditedInvestor`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async updateAccreditedInvestore(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/updateAccreditedInvestore`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }


  // add nda details of invetsor
  async submitNdaDetails(data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/submitNdaDetails`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getAllProposalsWithStatus() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getAllProposalsWithStatus`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async castVoteToAProposalByProposalUniqueId(proposalUniqueId,data) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/castVoteToAProposalByProposalUniqueId?proposalUniqueId=${proposalUniqueId}`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getAllInvestedTokenDetailsByInvestorId(investorId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getAllInvestedTokenDetailsByInvestorId?investorId=${investorId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async buyTokensWithPaymentDetailsAndCompanyId(companyId: any, data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/investor/buyTokensWithPaymentDetailsAndCompanyId?companyId=${companyId}`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getAllAvailableTokensForInvestor() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getAllAvailableTokensForInvestor`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async getOfferingDetailsByCompanyId(companyId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getNorthCapitalOfferingDetailsByCompanyId?companyId=${companyId}`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }
  async getRemainingSharesCount(companyId) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getRemainingSharesCountForACompanyByCompanyId?companyId=${companyId}`, 
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async getAllPaymentDetailsWithAllInvestorDetailsByCompanyId(companyId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/getAllPaymentDetailsWithAllInvestorDetailsByCompanyId?companyId=${companyId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async approveOrRejectPaymentRequestByCompanyId(companyId: any, data: any) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/issuerSuperAdmin/approveOrRejectPaymentRequestByCompanyId?companyId=${companyId}`,
      data,
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTotalInvestedInvestors(companyId: any) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/investor/getTotalInvestedInInvestor?companyId=${companyId}`,
      { headers: await sharedService.getAuthHeader() }
    );
  }
}
