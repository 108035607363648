import React, { useEffect, useState } from 'react';
import { Empty, Divider, message, Card, Row, Table, Progress ,Statistic , Button, Col, Spin, notification} from 'antd';
import { AuthService } from '../../Shared/Auth.service'
import { InvestorService } from '../Investor.service'
import { Link,useHistory } from 'react-router-dom';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
// import eversign from "./Eversign";
import InvestModel from '../../Shared/InvestModel/InvestModel';
import eversign from "../Home/Eversign";
import Payment from "../Home/Payment";
import KYCUniqueURLCard from "../KYCUniqueURLCard/KYCUniqueURLCard";
import {SecurityTokenRegistryService} from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()
const issuerSuperAdminService = new IssuerSuperAdminService();
const investorService = new InvestorService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();

const colorConfiguration = {
    textAlign: 'left',
    color: '#1890ff',
    fontSize: '15px',
    fontWeight: 'bold',
}

export default () => {
    const history = useHistory();
    const { userInfo, setUserInfo } = useUserContext();
    const [totalInvestor, setTotalInvestor] = useState();
    const [sharePercentage, setSharePercentage] = useState();
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [lastUpdatedDate, setLastUpdatedDate] = useState('');
    const [displayInvestModel, setDisplayInvestModel] = useState(false);
    const [investedTokens, setInvestedTokens] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingIT, setIsLoadingIT] = useState(true);
    const transactionDataSource = [];
    const netEstimatedDataSource = [];
    const [investmentCompanyId, setInvestmentCompanyId] = useState('');
    const [selectedCompanyData, setSelectedCompanyData] =useState('');
    const [walletAddressPresent] = useState(userInfo && userInfo.walletAddress ? userInfo.walletAddress : '');
    const [partnershipAgreementSigned, setPartnershipAgreementSigned] = useState(false);
    const [openEversign, setOpenEversign] = useState(false);
    const [signURL, setSignURL] = useState(null);
    const [PartnershipAgreementSignURL, setPartnershipAgreementSignURL] = useState(null);
    const [tokenDataSource, setTokenDataSource] = useState([]);
    const [selectedTokenSymbol, setSelectedTokenSymbol] = useState();
    const [tAndCView, setTAndCView] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [paymentModel, showPaymentModel] = useState(false);
    const [KYCLink, setKYCLink] = useState(null);


    const columns = [
        {
        title: 'Type of Security',
        dataIndex: 'typeOfSecurity',
        key: 'typeOfSecurity',
        },
        {
        title: 'Issuer Company',
        dataIndex: 'issuer',
        key: 'issuer',
        },
        {
        title: 'Token Count',
        dataIndex: 'tokenCount',
        key: 'tokenCount',
        },
        {
        title: 'Total Investors',
        dataIndex: 'totalInvestors',
        key: 'totalInvestors',
        },
        {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        },
        {
        title: 'Lock Period',
        dataIndex: 'lockPeriod',
        key: 'lockPeriod',
        },
        {
        title: 'Token Address',
        dataIndex: 'tokenAddress',
        key: 'tokenAddress',
        },
        {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        },
        {
        // title: 'Price Per Token',
        // dataIndex: 'pricePerToken',
        // key: 'pricePerToken',
        },
  ];

  useEffect(() => {
      const fetchInvestedTokens = async () => {
        try {
          const investorId = userInfo._id;
          const result =   await investorService.getAllInvestedTokenDetailsByInvestorId(investorId);
          if (result.success && result.data) {
              const tokensData =  await Promise.all(
                  result.data.map(async tokenDetails => {
                      if (!tokenDetails.tokenAddress && !!tokenDetails.tokenSymbol) {
                          const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(tokenDetails.tokenSymbol);
                          tokenDetails.tokenAddress = _symbolDetailsAndSTData?.securityTokenData?.contractAddress;
                      }
                      return {
                          key: tokenDetails._id,
                          issuer: tokenDetails.companyDetails?.name,
                          tokenCount: tokenDetails.tokenCount,
                          totalInvestors: tokenDetails.totalInvestors,
                          description: tokenDetails.description,
                          lockPeriod: tokenDetails.lockPeriod,
                          status: tokenDetails.status,
                          pricePerToken: tokenDetails.pricePerToken,
                          typeOfSecurity: tokenDetails.typeOfSecurity,
                          tokenAddress: tokenDetails.tokenAddress ? tokenDetails.tokenAddress : '',
                          creationTS: tokenDetails.creationTS
                      };
                  })
          );
              tokensData.sort((a, b) => new Date(b.creationTS) - new Date(a.creationTS));
              setInvestedTokens(tokensData);
            }
            setIsLoading(false);
        } catch (error) {
          console.error('Error fetching invested tokens:', error);
        }

        let dataToSend = {
          _id: userInfo._id,
          email: userInfo.email,
          role: userInfo.role,
          company:'a',
        };

        // if user has not kyc done or applied for
        if(!userInfo.status.hasOwnProperty('kyc')){
          const response = await investorService.generateLinkForKYC(dataToSend);
            if (response.success && response.data) {
              setKYCLink(response.data);
        }
  
    }
      };

      const getAllAvailableTokensForInvestor = async () => {
        setIsLoadingIT(true);
        const results = await investorService.getAllAvailableTokensForInvestor();
        console.log('results: ', results);
        if(results?.data?.length > 0) {
            let tempTokenDataSource = await Promise.all(
                results.data.map(async (token) => {
                    if (!token.tokenAddress && !!token.tokenSymbol) {
                        const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(token.tokenSymbol);
                        token.tokenAddress = _symbolDetailsAndSTData?.securityTokenData?.contractAddress;
                    }
                    return {
                        key: token._id,
                        companyname: token.companyDetails?.name || 'NA',
                        companyId: token.companyId,
                        tokenid: token._id,
                        propertyId: token?.propertyDetails?._id,
                        tokendate: new Date(token.creationTS).toLocaleString(),
                        status: token.status || '',
                        isTokenSaleClosed: token.hasOwnProperty('isTokenSaleClosed') && token.isTokenSaleClosed ? true : false,
                        pricePerToken: token?.pricePerToken,
                        bankData: token?.bankData,
                        projectedFundingDeadline: new Date(token?.propertyDetails?.projectedFundingDeadline).toLocaleString(),
                        bankData: token.bankInfo,
                        data: token,
                        tokenAddress: token.tokenAddress,
                        creationTS: token.creationTS
                    };
                })
            );
          tempTokenDataSource.sort((a, b) => new Date(b.creationTS) - new Date(a.creationTS));
          tempTokenDataSource = tempTokenDataSource.filter((dt) => dt?.key);
            tempTokenDataSource = tempTokenDataSource.filter(item => item.tokenAddress != "0x0000000000000000000000000000000000000000");
            console.log('tempTokenDataSource: ', tempTokenDataSource);
          await setCompanyDataBasedOnInvestorCountryType(tempTokenDataSource);
          setIsLoadingIT(false);
        }
      }

      // Fetch invested tokens when userInfo changes
      if (userInfo) {
        fetchInvestedTokens();
        getAllAvailableTokensForInvestor();
      }

      return () => {
        setTokenDataSource([]);
      };
  }, [userInfo]);

  /*
    filter data based on Investor-country-type by comparing the company's regularization.
    •	US issuer can sell tokens to US investors under Reg D and Reg A+ 
    •	Non- US issuer can sell tokens to NON US investors under Reg S
  */        
  const setCompanyDataBasedOnInvestorCountryType = async (tempTokenDataSource)=>{
    let data;
    if(userInfo.country === 'United States of America'){
      data = tempTokenDataSource.filter(inv => ['regulation-a-plus','regulation-d','regulation-d-b'].includes(inv.data?.regulation))
    }else{
      data = tempTokenDataSource.filter(inv => inv.data?.regulation === 'regulation-s')
    }
    setTokenDataSource(data);
    return
  }

    
      const clickedInvestButton = async (record, data) => {
        const isValid = await validateForInvestorType(record);
        if(!isValid) return

        console.log('333333 ',record,data)
        setSelectedRecord(record)
        setSelectedTokenSymbol(record.data.tokenSymbol)
        eversignurl(record)
        setIsLoading(true);
        setInvestmentCompanyId(record.companyId);
        setSelectedCompanyData(data);

        //if investor is of type PUBLIC, then assign assing company and redirct to kyc
        if(userInfo?.investorType?.toLocaleLowerCase() ==='public' && userInfo?.status?.kyc !== 'approved'){
          if(userInfo?.company?.id){
            window.location.href='/investor/kyc-status';
            return;
          }
          console.log('public user assingin company')
          assignCompanyToInvestor(record);
          return
        }
        //here he is public user and company not assigned then first call the company assign api and then redirect to kyc page and dont let the below agreement open for him.

        let investorsCount = await getInvestorsCount(
          record.companyname?.toLowerCase()
        );
        
        if (investorsCount >= record.data.totalInvestors) {
          message.info(
            "You can not invest in the token as total number of investors set by issuers is fullfield"
          );
          return;
        }
        if (userInfo?.tokens[record.data.tokenSymbol]?.tAndCSigned !== true) {
          // switchToTandCView();
          console.log('setting tc true')
          setTAndCView(true)
          // return;
        }else{
          showPaymentModel(true)
        }

        // now opening the partnership agreement agreement to sign it, once it is signed succesfully we will open the investment modal.
        // try {
        //   const response = await investorService.getPartnershipAgreementURL(userInfo);
        //   if(!response.success) {
        //     notification.open({
        //       message: 'Error',
        //       description: response.error.code,
        //       duration: 0,
        //     });
        //     setIsLoading(false);
        //     return false;
        //   }

        //   setIsLoading(false);
        //   setPartnershipAgreementSignURL(response.data);
        //   setOpenEversign(true);
        // } catch (error) {
        //   notification.open({
        //     message: 'Error',
        //     description: error,
        //     duration: 0,
        //   });
        // }
        
        setIsLoading(false);
      }

      const validateForInvestorType = async(record)=>{
        
        switch (record.data.regulation) {
          //if user is non-accredited then stop form investing
          case 'regulation-d':
            if(userInfo?.accreditedInvestor == 'false'){
              notification.info({message:'Not Allowed',description:'Ony accredited investors are allowed to invest in this property'})
              return false;
            }
            return true;
          case 'regulation-d-b':
            //if investor is non-accredited then check if they are > 35 and stop from investing
            if(userInfo?.accreditedInvestor === 'false'){
              const investedCount = await getTotalNumberOfNonAccreditedInvestedInvestors(record.companyId);
              if(!isNaN(investedCount) && investedCount > 35){
                notification.info({message:'Not Allowed',description:'This property allows only 35 non-accredited investors to invest'})
                return false;
              }else if (investedCount === -1){
                notification.error({message:'Error',description:'Something went wrong!'})
                return false;
              }
              return true;
            }
            return true;
        
          default:
            return true
        }
      }

      const getTotalNumberOfNonAccreditedInvestedInvestors = async(companyId)=>{
        try {
          const response = await investorService.getTotalInvestedInvestors(companyId);
          if(response.success){
            return response.data?.nonAccreditedCount;
          }
          return -1
        } catch (error) {
          console.error(error)
          return -1
        }
      }

      const getInvestorsCount = async (company) => {
        try {
          const response = await investorService.getInvestorsCount({ company });
          if (response.success && response.data) {
            return response.data.length;
          }
        } catch (e) {
          console.log(e);
          message.error("can't fetch details of token");
          return -1;
        }
        return -1;
      };
      
    async function assignCompanyToInvestor(record) {
      try {
        setIsLoading(true);
        const response = await investorService.updatePublicInvestorWithCompanyDetails(record.companyId,{investorId:userInfo?._id});
          if(!response.success) {
            notification.open({
              message: 'Error',
              description: response.error.code,
              duration: 0,
            });
            setIsLoading(false);
            return;
          }
          notification.open({
            message: 'Success',
            description: 'Success',
            duration: 0,
          });
          
          setTimeout(() => {
            window.location.href='/investor/kyc-status';
          }, 500);
      } catch (error) {
        notification.open({
          message: 'Error',
          description: error,
          duration: 0,
        });
      }
      setIsLoading(false);
    }


    const tokenColumns = [
        {
          title: 'Company Name',
          dataIndex: 'companyname',
          key: 'companyname',
        },
        {
          title: 'Token Address',
          dataIndex: 'tokenAddress',
          key: 'tokenAddress',
        },
        {
          title: 'Token Creation Date',
          dataIndex: 'tokendate',
          key: 'tokendate',
        },
        {
          title: 'Token Sale End Date',
          dataIndex: 'projectedFundingDeadline',
          key: 'projectedFundingDeadline',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Property link',
          key: 'propertylink',
          render: (record) => {
              return (
                <>
                    <Link to={`/property-details?propertyId=${record.propertyId}`}><Button type="link">Link</Button></Link>
                </>
              );
            },
        },
        {
          title: 'Invest ',
          key: 'invest',
          render: (record,data) => {
              return (
                <>
                    {record.isTokenSaleClosed ? 'CLOSED': ((<Button
                      type="link"
                      onClick={() => clickedInvestButton(record, data)}
                    >
                      Invest
                    </Button>))}
                </>
              );
            },
        },
      ];

    useEffect(() => {
        (async () => {
            const result = await new InvestorService().getPayments({ investorId: userInfo._id });
            if (result.success && result.data) {
                setPaymentDetails(result.data.filter(t => t.accept === true));
            }else{
            }
        })();

    }, [userInfo._id]);

    const cancelInvestModel = ()=>{
        setDisplayInvestModel(false);
      }

    const onSignPartnershipAgreement = async () => {
      setPartnershipAgreementSigned(true);
      const response = await investorService.setPartnershipAgreementSigned({
        investorId: userInfo?._id,
        investmentCompanyId: investmentCompanyId,
        signedDocumentUrl: signURL,
      });
      if (response.success && response.data) {
        setPartnershipAgreementSigned(true);
        setDisplayInvestModel(true);
        setOpenEversign(false);
      }
    };

    const eversignurl = async (record) => {
      console.log('everysing',record)
      if(record.data.tokenSymbol){
        if (userInfo?.tokens && userInfo?.tokens[record.data.tokenSymbol]?.tAndCSigned === true){
          setOpenEversign(true); 
          return;
        }

        if(record.data){
          const data = Object.assign({tokenCompanyId: record.companyId},userInfo);
          const response = await investorService.getSignURL(data);
          await setSignURL(response.data);
          setOpenEversign(true);  
        }
      }
    }

    const onSignTC = async() => {
      setTAndCView(false);
      const response = await investorService.acceptTandC({
        email: userInfo.email,
        tokenSymbol: selectedTokenSymbol
      });
      if (response.success && response.data) {
        // window.location.reload();
        // setOpenEversign(false)
        showPaymentModel(true)
      }
    };

    return (
        <>
        <Row justify="center">
          <Col span={24}>
          {isLoading &&
              <div style={{ textAlign: 'center' }}>
                <br />
                <Spin size='large' />
              </div>
            }
          {!isLoading && (<>

            {
              (userInfo?.status?.kyc !== 'approved')  && (
                <div>
                  {/* You can add any additional content here for users with approved KYC */}
                  {(KYCLink || userInfo?.status?.kyc !== 'approved') && <KYCUniqueURLCard uniqueURL={KYCLink} />}
                </div>
              ) 
            }

            
            {!openEversign && displayInvestModel && <InvestModel displayInvestModel={displayInvestModel} handleCancel={cancelInvestModel} companyData={selectedCompanyData} investmentCompanyId={investmentCompanyId}></InvestModel>}
            {paymentModel && (
                  <Payment
                    user={userInfo}
                    onBack={()=>{setOpenEversign(false);showPaymentModel(false)}}
                    currentToken={{issuerInfo:[selectedRecord]}}
                    selectedTokenSymbol={selectedRecord.data.tokenSymbol}
                    issuerCompanyId={selectedRecord.companyId}
                  />
            )}
            {!openEversign && (walletAddressPresent && walletAddressPresent.length > 0 || userInfo?.investorType === 'public') 
            && userInfo?.status?.kyc === 'approved'
            && 
            <>
              <Row>
              <Col span={23}>
                <Table dataSource={tokenDataSource} loading={isLoadingIT} columns={tokenColumns} scroll={{x: true}} pagination={{ pageSize: 5}} />
              </Col>
            </Row>
            <Row>
              <br></br>
            </Row>
            <Row>
              <Card title={
                <Divider orientation="left" style={colorConfiguration}> Invested Tokens </Divider>
              } bordered={true} style={{ width: "100%" }}>
                <Table dataSource={investedTokens} columns={columns} scroll={{x: true}} pagination={{ pageSize: 5}}/>
              </Card>
            </Row>
            </>
            }

            {(!openEversign && !walletAddressPresent)   &&
            <>
            
              <Card >
                <div className="noWallet" style={{textAlign:'center'}}>
                  <h3>Wallet Address Not Found</h3>
                  <Button
                    type="link"
                    onClick={() => history.push('/connect-wallet')}
                  >
                    Connet Wallet
                  </Button>
                </div>
              </Card>
            
            </>}

            {/* Partnership Agreement View Starts Here */}
            <div className="col-12 d-flex justify-content-center">
            <div hidden={partnershipAgreementSigned } id="embedSignDivisionpa" >
              {/* <h1>Partnership Agreement</h1> */}
              {(openEversign && !partnershipAgreementSigned && PartnershipAgreementSignURL ) &&
                eversign.open({
                  url: PartnershipAgreementSignURL,
                  containerID: "embedSignDivisionpa",
                  width: 750,
                  height: 800,
                  events: {
                    loaded: () => console.log("loading successful"),
                    signed: onSignPartnershipAgreement,
                    error: () => console.log("EVERSIGN ERROR"),
                    declined: () => console.log("declined"),
                  },
                })}
            </div>
            </div>
            {/* Partnership Agreement View ends here */}

            {/* Terms and Conditions View Starts Here */}
            <div className='col-12 d-flex justify-content-center'>
            <div hidden={!tAndCView} id="embedSignDivisiontc" >
              {/* <h1>Terms and Conditions</h1> */}
                
                  {(openEversign && tAndCView) &&
                    eversign.open({
                      url: signURL,
                      containerID: "embedSignDivisiontc",
                      width: 750,
                      height: 800,
                      events: {
                        loaded: () => console.log("loading successful"),
                        signed: onSignTC,
                        error: () => console.log("EVERSIGN ERROR"),
                        declined: () => console.log("declined"),
                      },
                    })}
              </div>
              </div>
              {/* Terms and Conditions View ends here */}


            <Row>
                <br></br>
            </Row>
          </>)}
              
          </Col>
        </Row>
        </>
    )
}