import axios from 'axios';
import {environment} from '../../environments/environment';
import {APIResponse} from '../Shared/interfaces';
import {SharedService} from '../Shared/Shared.service';

// import { SecurityTokenService } from '../Shared/SecurityToken/SecurityToken.service';
import WhitelistFacet from '../Shared/SecurityToken/Facets/WhitelistFacet/index';

const sharedService = new SharedService();

// const securityTokenService = new SecurityTokenService();
const whitelistFacet = new WhitelistFacet();

export class IssuerComplianceAdminService {

  private APIURL = environment.APIURL;
  async getInvestorKYCRequests(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerComplianceAdmin/getInvestorKYCRequests`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getTeamMemberKYCRequests(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerComplianceAdmin/getTeamMemberKYCRequests`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getInvestorsList(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerComplianceAdmin/getInvestorsList`,data,{headers:await sharedService.getAuthHeader()});
  }

  async getTeamMemberList(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerComplianceAdmin/getTeamMemberList`,data,{headers:await sharedService.getAuthHeader()});
  }

  async updateKYC(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerComplianceAdmin/updateKYC`,data,{headers:await sharedService.getAuthHeader()});
  }

  async resubmitKYC(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerComplianceAdmin/resubmitKYC`,data,{headers:await sharedService.getAuthHeader()});
  }
  async updateTeamMemberKYC(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerComplianceAdmin/updateTeamMemberKYC`,data,{headers:await sharedService.getAuthHeader()});
  }

  async getAIVerificationRequest(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/getAIVerificationRequest`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getAIInvestorsList(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/getInvestorsList`,data,{headers:await sharedService.getAuthHeader()});
  }
  async acceptAI(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/acceptAI`,data,{headers:await sharedService.getAuthHeader()});
  }
  async rejectAI(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/rejectAI`,data,{headers:await sharedService.getAuthHeader()});
  }
  async resubmitAI(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/resubmitAI`,data,{headers:await sharedService.getAuthHeader()});
  }

  async getPendingWhitelistedWallets(contractAddress: string,companyId=null) {
    const _whitelistData = await whitelistFacet.getWhitelist(contractAddress);

    const addresses = _whitelistData.map(data => data.wallet);
    console.log(addresses);

    return axios.get<any, APIResponse>(`${this.APIURL}/issuerComplianceAdmin/getPendingWhitelistedWallets`, {
      params: {addresses:addresses.toString(), companyId},
      headers: await sharedService.getAuthHeader()
    });
  }


  async getWhitelistedUsersByWallet(contractAddress: string,companyId=null) {
    const _whitelistData = await whitelistFacet.getWhitelist(contractAddress);
    console.log("_whitelistData",_whitelistData);
    const addresses = _whitelistData.map(data => data.wallet);
    console.log(addresses);

    const response = await axios.get<any, APIResponse>(`${this.APIURL}/issuerComplianceAdmin/getWhitelistedUsersByWallet`, {params: {addresses: addresses.toString(), companyId}, headers: await sharedService.getAuthHeader()});

    if(response.success) {
      response.data = (response.data as any[]).map(info => ({
        ...info.user,
        ..._whitelistData.find(data => data.wallet.toLowerCase() === info.wallet)
      }));
    }

    return response;
  }

     // Persist proposal details
     async  persistProposalDetails(companyId,data) {
      return axios.post<any, APIResponse>(
        `${this.APIURL}/issuerComplianceAdmin/saveProposalByIdByCompanyId?companyId=${companyId}`,data,
        { headers: await sharedService.getAuthHeader() }
      );
    }
     async  publishNewProposalByPoposalIdAndCompanyId(companyId,proposalId,data) {
       console.log('umar logs payload ',data);
      return axios.post<any, APIResponse>(
        `${this.APIURL}/issuerComplianceAdmin/publishNewProposalByPoposalIdAndCompanyId?companyId=${companyId}&proposalId=${proposalId}`,data,
        { headers: await sharedService.getAuthHeader() }
      );
    }
     async publishProposalDetails(companyId,proposalId) {
      return axios.get<any, APIResponse>(
        `${this.APIURL}/issuerComplianceAdmin/publishProposalByPoposalIdAndCompanyId?companyId=${companyId}&proposalId=${proposalId}`,
        { headers: await sharedService.getAuthHeader() }
      );
    }

    async getNewProposalId() {
      return axios.get<any, APIResponse>(
        `${this.APIURL}/issuerComplianceAdmin/getNewProposalId`,
        { headers: await sharedService.getAuthHeader() }
      );
    }
    async getProposalByCompanyId(companyId) {
      return axios.get<any, APIResponse>(
        `${this.APIURL}/issuerComplianceAdmin/getProposalByPoposalIdAndCompanyId?companyId=${companyId}`,
        { headers: await sharedService.getAuthHeader() }
      );
    }

}
