import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Card,
  Col,
  Row,
  Typography,
  Descriptions,
  Button,
  Tabs,
  Radio,
  message,
  Input,
  notification,
} from "antd";

import { IssuerComplianceAdminService } from "../IssuerComplianceAdmin.service";
import {ProjectOutlined } from "@ant-design/icons";
import { SharedService } from "../../Shared/Shared.service";
import moment from "moment";
import { AuthService } from "../../Shared/Auth.service";
import { PropertyAdminService } from "../../PropertyDetails/PropertyAdmin.service";
import { SecurityTokenRegistryService } from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import WhitelistFacet from "../../Shared/SecurityToken/Facets/WhitelistFacet";
import { MetamaskService } from "../../Shared/Metamask.service";
import TxFeeDelegationModal from "../../Shared/TxFeeDelegationModal";
import { WhitelistedWallet } from "../../Shared/interfaces";


const { Title } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const issuerComplianceAdminService = new IssuerComplianceAdminService();

const useUserContext = () => new AuthService().useUserContext();
const sharedService = new SharedService();
const propertyAdminService = new PropertyAdminService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const whitelistFacet = new WhitelistFacet();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

const displayOptions = {
    AfterRejectDivision: "2",
    MainDivision: "1",
};
const subDisplayOptions = {
    RejectView: "1",
    ResubmitView: "2",
};

export default () => {
  const [loading, setLoading] = useState(true);
  const { userInfo, setUserInfo } = useUserContext();
  const [investorDetails, setInvestorDetails] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [detailsDivision, setDetailsDivision] = useState(true);
  const [whitelistedView, setWhitelistedView] = useState(false);
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [investorWalletData, setInvestorWalletData] = useState([]);
  const [approvedAndRejectedKYCRequests, setInvestorsList] = useState([]);
  const [pendingWhitelistData, setPendingWhitelistData] = useState<any>([]);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<any>();
  const [whitelistData, setWhitelistData] = useState<WhitelistedWallet[]>([]);
  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);


  const columns = [
    {
        title: 'Property Name',
        dataIndex: ['propertyTitle'],
        key: 'name'
    },
    {
        title: 'Category',
        dataIndex: 'propertyCategoryType',
        key: 'propertyCategoryType'
    },
    {
        title: 'Country',
        dataIndex: 'propertyCountry',
        key: 'propertyCountry'
    },
    {
        title: 'ACTION',
        dataIndex: 'action',
        key: 'action',
        render: (text, prop) => <Button type='primary' onClick={() => viewInvestors(prop)}>View Investors</Button>
    }
  ];

  const columns2 = [
    {
        title: 'Property Name',
        dataIndex: ['propertyTitle'],
        key: 'name'
    },
    {
        title: 'Category',
        dataIndex: 'propertyCategoryType',
        key: 'propertyCategoryType'
    },
    {
        title: 'Country',
        dataIndex: 'propertyCountry',
        key: 'propertyCountry'
    },
    {
        title: 'ACTION',
        dataIndex: 'action',
        key: 'action',
        render: (text, prop) => <Button type='primary' onClick={() => viewInvestors(prop,true)}>View Investors</Button>
    }
  ];
  
  const investorWalletColumns = [
    {
        title: 'Investor Name',
        dataIndex: whitelistedView ? 'firstName' : ['user','firstName'],
        key: 'name'
    },
    {
      title: 'Wallet Address',
      dataIndex: 'wallet',
      render: (value: string, record) => 
      <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>
        {sharedService.minifyAddress(value)}
      </a> 
    },
  ];


  useEffect(() => {
    (async () => {
      setLoading(true);
      let data = { companyId: userInfo?.company?.id };
      console.log( userInfo?.company?.id," userInfo.companyId");
      const response = await propertyAdminService.getWhiteListedProperties();
      setInvestorsList(response?.data);
      setLoading(false);
    })();
  }, [userInfo?.company?.id]);

  const viewInvestors = async(property,whitelistedView=false) => {
    setLoading(true);
    console.log('getting stdata...')
    const _symbolDetailsAndSTData = await getSymbolDetailsAndSTData(property);

    localStorage.setItem('selectedRecord',JSON.stringify({'propertyId':property?.companyId,contractAddress:_symbolDetailsAndSTData?.securityTokenData.contractAddress}))

    if(whitelistedView){
      await getWhitelistedWalletAddresses(_symbolDetailsAndSTData,property?.companyId);
    }else{
      await getInvestorsWithPendingWalletAddresses(_symbolDetailsAndSTData,property?.companyId);
    }
    setLoading(false);

    console.log(_symbolDetailsAndSTData)
    setDetailsDivision(false);
    setInvestorDetails(property);
  };


  const getSymbolDetailsAndSTData = async(property)=>{
    try {
        console.log('tokensymbol ',property?.tokenSymbol)
        const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(property && property?.tokenSymbol);
        console.log('after :',_symbolDetailsAndSTData)
        await setSymbolDetailsAndSTData(_symbolDetailsAndSTData);
        return _symbolDetailsAndSTData
    } catch (error) {
        console.log(error)
        notification.error({message:'Error',description:error})
    }
  }

  const getInvestorsWithPendingWalletAddresses = async(_symbolDetailsAndSTData, companyId)=>{
    try {
        if(!_symbolDetailsAndSTData) return;
        console.log('contract address :',_symbolDetailsAndSTData?.securityTokenData.contractAddress)
        const pendingWallets = await issuerComplianceAdminService.getPendingWhitelistedWallets(_symbolDetailsAndSTData?.securityTokenData.contractAddress,companyId);
        console.log('pending Wallets :', pendingWallets)
        setInvestorWalletData(pendingWallets.data)
    } catch (error) {
        console.log(error)
        // notification.error({message:'Error',description:error})
    }
  }
  const getWhitelistedWalletAddresses = async(_symbolDetailsAndSTData,companyId)=>{
    try {
        if(!_symbolDetailsAndSTData) return;
        console.log(_symbolDetailsAndSTData)
        console.log('contract address :',_symbolDetailsAndSTData?.securityTokenData.contractAddress)
        const whitelistedWallets = await issuerComplianceAdminService.getWhitelistedUsersByWallet(_symbolDetailsAndSTData?.securityTokenData.contractAddress,companyId);
        console.log('pending Wallets :', whitelistedWallets)
        setInvestorWalletData(whitelistedWallets.data);
        console.log(whitelistedWallets)
        return true
    } catch (error) {
        console.log(error)
        // notification.error({message:'Error',description:error})
    }
  }



  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setPendingWhitelistData(selectedRows);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: false
      };
    }
  };

  const sendWalletsForWhitelisting = ()=>{
    setIsDelegationModalVisible(true);
  }

  const addWhitelistedWalletMulti = async (prop: { delegate: boolean }) => {
    if (!pendingWhitelistData || !pendingWhitelistData.length) {
        notification.info({ message: 'No Record', description: "Please select a record first!" });
        return false;
    }

    // if(pendingWhitelistData.find(whitelistMember => !whitelistMember.wallet)) return message.error('Enter all the wallets');

    // setIsModalVisible(true);
    // setTransactions([{details: 'Adding to Whitelist', submitting: true}]);

    console.log(pendingWhitelistData);

    const _from: string[] = [];
    const _isAffiliate: boolean[] = [];
    const _isAccredetedInvestor: boolean[] = [];
    const _country: string[] = [];

    pendingWhitelistData.forEach((whitelistMember: any) => {
        _from.push(whitelistMember?.wallet || ''); // Ensure it's not undefined
        _isAffiliate.push(whitelistMember?.isAffiliate || false); // Default to false
        _isAccredetedInvestor.push(whitelistMember?.isAccredetedInvestor || false); // Default to false
        _country.push(whitelistMember?.country || 'USA'); // Ensure it's not undefined
    });

    console.log(_from, _isAffiliate, _country, _isAccredetedInvestor);

    try {
        setLoading(true);
        const receipt = await whitelistFacet.addWhitelistedWalletMulti(
            symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
            selectedWallet as string,
            _from,
            _isAffiliate,
            _country,
            _isAccredetedInvestor,
            { delegate: prop.delegate }
        );
        // setTransactions(prev => {
        //     const current = sharedService.clone(prev);
        //     current[0].receipt = receipt;
        //    return current;
        // });

        console.log(receipt);

        if (receipt?.status) {
            setPendingWhitelistData([]);
            notification.success({ message: 'Success', description: `${pendingWhitelistData.length} Wallet(s) has been whitelisted successfully!` });
            
            try {
              const record = JSON.parse(localStorage.getItem('selectedRecord'))
              console.log(record)
              await getInvestorsWithPendingWalletAddresses(record.contractAddress,record.companyId);
              if(investorWalletData.length <= 0){
                localStorage.removeItem('selectedRecord');
              }
            } catch (error) {
              console.error(error);
              // window.location.reload();
            }
            
        }
        setLoading(false);
    } catch (error) {
        const err:any = error
        notification.error({ message: 'Error', description: err.message });
        setLoading(false);
    }

    // setTransactions(prev => {
    //   const current = sharedService.clone(prev);
    //   current[0].submitting = false;
    //   return current;
    // });
}


  const goBack = ()=>{
    setDetailsDivision(true);
    setInvestorWalletData([])
    setWhitelistData([])
    setSelectedRowKeys([]);
  }
  const handleTabChange = (key)=>{
    key == 2 ? setWhitelistedView(true) : setWhitelistedView(false)
    goBack()
  }
  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Whitelist Wallet Requests
            </Title>

            <Tabs defaultActiveKey="1" centered onChange={handleTabChange}>
            
            <TabPane
                tab={
                  <span>
                    <ProjectOutlined />
                    Pending Whitelisted Wallets
                  </span>
                }
                key="1"
              >
                <div hidden={!detailsDivision}>
                  <Table
                    columns={columns}
                    dataSource={approvedAndRejectedKYCRequests}
                    loading={loading}
                  ></Table>
                </div>
              </TabPane>

              <TabPane
                tab={
                  <span>
                    <ProjectOutlined />
                    Whitelisted Wallets
                  </span>
                }
                key="2"
              >
                <div hidden={!detailsDivision}>
                  <Table
                    columns={columns2}
                    dataSource={approvedAndRejectedKYCRequests}
                    loading={loading} 
                  ></Table>
                </div>
              </TabPane>

            </Tabs>
            {/* details sections */}
            <div hidden={detailsDivision}>
                  <Table
                    columns={investorWalletColumns}
                    dataSource={investorWalletData}
                    loading={loading} 
                    scroll={{ x: true }}
                    rowKey="_id"
                    rowSelection={whitelistedView ? undefined : rowSelection} 
                  ></Table>

                
                  <div style={{ display:'flex',justifyContent:'end',gap:'20px' }}>
                    <br />
                    <Button
                        size="large"
                        type="primary"
                        onClick={()=> goBack()}
                    >
                        Back
                    </Button>
                   {!whitelistedView && <Button
                        size="large"
                        type="primary"
                        onClick={sendWalletsForWhitelisting}
                    >
                        Send For Whitelisting
                    </Button>}
                </div>
                </div>
          </Card>
        </Col>
      </Row>

      <TxFeeDelegationModal
        isVisible={isDelegationModalVisible}
        onSubmit={({delegate}) => {
          setIsDelegationModalVisible(false);
          addWhitelistedWalletMulti({delegate}).then()
        }}
        onCancel={() => setIsDelegationModalVisible(false)}
      />
    </>
  );
};
